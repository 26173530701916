import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTelegram } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Navbar } from "react-bootstrap";
import {links} from "../const/links";

const NavbarComponent = ({ openSideBar }) => {
  const [className, setClassName] = useState("position-absolute");
  const handleScroll = () => {
    if (window.scrollY > 120) {
      if (className !== "fixed-top") setClassName("fixed-top");
    } else if (className !== "") setClassName("");
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Navbar
      bg="dark"
      expand="md"
      className={"NavbarComponent d-flex navbar " + className}
    >
      <NavLink to={""} className="navbar-brand">
        <img src={"images/logo.png"} alt="logo" className="navbar-logo" />
      </NavLink>
      <Navbar.Toggle
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <GiHamburgerMenu />
      </Navbar.Toggle>

      <Navbar.Collapse
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav ms-auto">
          <li className="nav-item active">
            <NavLink
              to={"/"}
              className={(navData) =>
                navData.isActive ? "nav-link" : "nav-link"
              }
            >
              Главная
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={"about-us"}
              className={(navData) =>
                "nav-link " + (navData.isActive ? "active" : "")
              }
            >
              О нас
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={"our-services"}
              className={(navData) =>
                "nav-link " + (navData.isActive ? "active" : "")
              }
            >
              Наши Услуги
            </NavLink>
          </li>
        </ul>
      </Navbar.Collapse>
      <div className="socials d-md-flex d-none">
        <a href={links.facebook}>
          <FaFacebook size={16} />
        </a>
        <a href={links.instagram}>
          <FaTelegram size={16} />
        </a>
        <a href={links.instagram}>
          <FaInstagram size={16} />
        </a>
      </div>
      <button
        className="btn d-flex align-items-center justify-content-center box-70 d-md-block d-none"
        onClick={openSideBar}
      >
        <GiHamburgerMenu size={32} className="color-white" />
      </button>
    </Navbar>
  );
};

export default NavbarComponent;
