import { Helmet } from "react-helmet";

import CountUp from "react-countup";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaChevronRight,
  FaPaperPlane,
  FaPhone,
  FaPlay,
  FaUser,
} from "react-icons/fa";
import { Carousel, Modal, Toast, ToastContainer } from "react-bootstrap";
import { links, service } from "../const/links";
import { useState } from "react";
import { sendMessage } from "../request";

export default function Home() {
  const fashShipping = "images/home/fash-shipping.png";
  const goodsSearch = "images/home/goods-search.png";
  const aboutUs = "images/home/about-us.png";
  const reviewVideo = "images/home/review-video.jpg";
  const video_src = "video/video.mp4";

  const [modalShow, setModalShow] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  return (
    <div className="Home p-0">
      <Helmet>
        <title>UzPartnerCargo - Logistics company</title>
        <meta name="description" content="Generated by create next app" />
      </Helmet>

      <main>
        <header className="container-fluid p-0 min-vh-100">
          <Carousel
            className="carousel"
            nextIcon={
              <div className="control-icon">
                <FaAngleDoubleRight />
              </div>
            }
            prevIcon={
              <div className="control-icon">
                <FaAngleDoubleLeft />
              </div>
            }
            indicators={false}
          >
            {service.map((item, index) => (
              <Carousel.Item className="carousel-item" key={index}>
                <img
                  className="d-block w-100"
                  src={item.img_src}
                  alt={item.title}
                />
                <Carousel.Caption>
                  <div className="container">
                    <h2 className="size-lg">{item.title}</h2>
                    <div className="d-flex justify-content-center">
                      <a href={links.admin} className="btn btn-red">
                        Связаться с нами
                        <FaPaperPlane />
                      </a>
                      <a href="/#/our-services" className="btn btn-white">
                        Все услуги
                        <FaChevronRight />
                      </a>
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </header>

        <section className="counter container">
          <div className="row g-5">
            <div className="col-md-4">
              <CountUp
                className="h1"
                end={1800}
                formattingFn={(n) => {
                  return String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");
                }}
                duration={2}
              />
              <p className="counter-title">Доставленные товары</p>
            </div>
            <div className="col-md-4">
              <CountUp
                className="h1"
                end={1200}
                formattingFn={(n) => {
                  return String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");
                }}
                duration={2}
              />
              <p className="counter-title">Довольные клиенты</p>
            </div>
            <div className="col-md-4">
              <CountUp
                className="h1"
                end={1080}
                formattingFn={(n) => {
                  return String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");
                }}
                duration={2}
              />
              <p className="counter-title">Сделок закрыто в этом году</p>
            </div>
          </div>
        </section>

        <section className="tracking container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 title">
                <span className="size-lg border-text color-white">01</span>
                <h1 className="section-title color-white">
                  <span className="before-border color-white me-5" />
                  Отслеживание
                </h1>
                <h2>Не пропустите свой пакет</h2>
              </div>
              <div className="col-lg-6 content">
                <p>Введите номер груза</p>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Enter tracking number"}
                  />
                  <button className="btn btn-secondary">Результат</button>
                </div>
                <p>Например: WPC123456789123-UzPartnerCargo</p>
              </div>
            </div>
          </div>
        </section>

        <section className="about-us container-fluid py-5">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="position-relative w-100 pe-5">
                  <img src={aboutUs} className="w-100" alt="" />
                  <div
                    className="position-absolute color-white px-5 py-4 d-flex align-items-center"
                    style={{
                      bottom: 0,
                      right: 0,
                      width: 340,
                      backgroundColor: "red",
                      borderRadius: 12,
                    }}
                  >
                    <p className="fw-bold display-5 me-3">
                      10
                      <sup className="fw-normal ms-2">+</sup>
                    </p>
                    <h4>Годы опыта</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <span className="size-lg border-text color-black">02</span>
                <h1 className="section-title">
                  <span className="before-border" />О нас{" "}
                </h1>
                <h1 className="text-uppercase display-5 fw-bold mt-3 mb-4">
                  Uz Partner Cargo
                </h1>
                <div className="d-flex flex-row mb-3">
                  <div
                    className="w-px-100"
                    style={{
                      margin: "0 20px 0 0",
                    }}
                  >
                    <img src={fashShipping} className="w-100" alt="" />
                  </div>
                  <div className="w-100">
                    <h3 className="fw-bold">
                      Быстрые и безопасные Грузоперевозки
                    </h3>
                    <p>
                      Мы осуществляем достайну стандартных грузда, сборных,
                      опасных и требующих специальных условий транспортировки,
                      негабаритных и тяжеловесных
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div
                    className="w-px-100"
                    style={{
                      margin: "0 20px 0 0",
                    }}
                  >
                    <img src={goodsSearch} className="w-100" alt="" />
                  </div>
                  <div className="w-100">
                    <h3 className="fw-bold">
                      Поиск готовой продукции, сырья, оборудования в Китае
                    </h3>
                    <p>
                      UZ PARTNER CARCO найдёт все необходимое для вашего дела по
                      лучшим ценам, проверит поставщиков и предоставит контакты,
                      поможет сакаться провести переговоры
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-service container-fluid">
          <div className="container">
            <div className="offset-md-2 col-md-8 d-flex align-items-center flex-column position-relative">
              <span className="size-lg border-text color-black">03</span>
              <h1 className="section-title">
                <span className="before-border" />
                Наш сервис
                <span className="after-border" />
              </h1>
              <h1 className="text-center fw-bold mt-3 mb-4">
                Мы организовываем грузоперевозки «под ключ»
              </h1>
            </div>
            <div className="row g-3">
              <div className="col-lg-4 col-md-6">
                <div className="card border-0">
                  <div
                    className="card-header border-0 p-0 overflow-hidden"
                    style={{ height: 250 }}
                  >
                    <img
                      src={"/images/home/rail-cargo.jpg"}
                      alt=""
                      className="fit-center"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <h3>Железнодорожные грузоперевозки</h3>
                      <a href="/" className="btn rounded-circle">
                        <FaAngleDoubleRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card border-0">
                  <div
                    className="card-header border-0 p-0 overflow-hidden"
                    style={{ height: 250 }}
                  >
                    <img
                      src={"/images/home/auto-cargo.jpg"}
                      alt=""
                      className="fit-center"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <h3>Автомобильные грузоперевозки</h3>
                      <a href="/" className="btn rounded-circle">
                        <FaAngleDoubleRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card border-0">
                  <div
                    className="card-header border-0 p-0 overflow-hidden"
                    style={{ height: 250 }}
                  >
                    <img
                      src={"/images/home/avia-cargo.jpg"}
                      alt=""
                      className="fit-center"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <h3>Воздушные грузоперевозки </h3>
                      <a href="/" className="btn rounded-circle">
                        <FaAngleDoubleRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card border-0">
                  <div
                    className="card-header border-0 p-0 overflow-hidden"
                    style={{ height: 250 }}
                  >
                    <img
                      src={"/images/home/search-cargo.jpg"}
                      alt=""
                      className="fit-center"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <h3>Поиск товаров из Китая</h3>
                      <a href="/" className="btn rounded-circle">
                        <FaAngleDoubleRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card border-0">
                  <div
                    className="card-header border-0 p-0 overflow-hidden"
                    style={{ height: 250 }}
                  >
                    <img
                      src={"/images/home/customs-service.jpg"}
                      alt=""
                      className="fit-center"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <h3>Таможенное оформление</h3>
                      <a href="/" className="btn rounded-circle">
                        <FaAngleDoubleRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="request-quote container-fluid">
          <div className="container">
            <div className="row g-5 justify-content-center">
              <div className="offset-lg-2 col-lg-7 position-relative ps-5">
                <div className="title-content">
                  <span className="size-lg border-text color-black">04</span>
                  <h1 className="section-title">
                    <span className="before-border" />
                    Запрос цитаты
                  </h1>
                  <h2 className="fw-bold mt-3 mb-2">
                    Получите расчёт доставки вашего груза в течении 24 часов
                  </h2>
                </div>
                <form
                  onSubmit={async (e) => {
                    try {
                      e.preventDefault();
                      const formDate = new FormData(e.target);
                      const data = Object.fromEntries(formDate);
                      await sendMessage(data);
                      e.target.reset();
                      setToastShow(true);
                      setToastMessage("Сообщение отправлено");
                    } catch (error) {
                      setToastShow(true);
                      setToastMessage("Ошибка отправки сообщения");
                    }
                  }}
                >
                  <div className="forms-group">
                    <div className="row g-4">
                      <div className="col-md-6">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ваше имя"
                            name="Ism"
                          />
                          <FaUser size={20} className="color-red" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Номер телефона"
                            name="Tel"
                          />
                          <FaPhone size={20} className="color-red" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          min={0}
                          className="form-control"
                          placeholder="метр³"
                          name="kub"
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          min={0}
                          className="form-control"
                          placeholder="кг"
                          name="kg"
                        />
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn w-100 bg-red color-white rounded-pill"
                          type="submit"
                        >
                          Отправить
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="review container-fluid">
          <div className="container">
            <div className="row gy-0">
              <div className="col-lg-5">
                <div className="title-content">
                  <span className="size-lg border-text color-black">05</span>
                  <h1 className="section-title">
                    <span className="before-border" />
                    Обзор
                  </h1>
                  <h2 className="fw-bold mt-3 mb-2">
                    Что клиенты говорят о UZ PARTNER CARGO{" "}
                  </h2>
                </div>
                <p className="small">
                  Для нас каждый клиент на вес золота! Мы стараемся
                  удовлетворить все Ваши потребности оказывая максимальную
                  клиентоориентированность!
                </p>
              </div>
              <div className="col-lg-6">
                <div className="col-lg-12">
                  <div className="video-content position-relative">
                    <img src={reviewVideo} className="w-100" alt="" />
                    <div className="overlay">
                      <span className="btn" onClick={() => setModalShow(true)}>
                        <FaPlay className="color-white" size={20} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        size="lg"
      >
        <Modal.Body>
          <video width="100%" controls>
            <source src={video_src} type="video/mp4" />
          </video>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" containerPosition="fixed">
        <Toast
          show={toastShow}
          onClose={() => setToastShow(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">UZ PARTNER CARGO</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
