import { HashRouter as BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages";
import AboutUs from "./pages/about-us";
import Layout from "./components/Layout";
import OurServices from "./pages/our-services";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="our-services" element={<OurServices />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
