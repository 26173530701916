import axios from "axios";
import { BOT_LINK } from "./const/links";

const sendMessage = (json) => {
  const chat_id = 1758268337; // this is my chat id
  json["Vaqti"] = new Date().toLocaleString("uz-UZ", {
    timeZone: "Asia/Tashkent",
  });
  let myText = Object.keys(json).map((key) => {
    return `<b>${key}</b>: ${json[key]}`;
  });
  myText = myText.join("\n\n");
  const encodedText = encodeURI(myText);
  return axios.post(
    `${BOT_LINK}/sendMessage?chat_id=${chat_id}&text=${encodedText}&parse_mode=HTML`
  );
};

export { sendMessage };
