import React from "react";
import { MdClose } from "react-icons/md";
import { address, links } from "../const/links";
import { FaMapMarkerAlt } from "react-icons/fa";

const RightSideBar = ({ show, closeSideBar }) => {
  return (
    <div className={"RightSideBar " + (show && "show")}>
      <div className="d-flex">
        <button className="btn close-btn ms-auto">
          <MdClose size={16} className="color-red" onClick={closeSideBar} />
        </button>
      </div>
      <img src={"images/logo.png"} className="w-100" alt="" />
      <div className="main-content">
        <div className="side-info">
          <h4>Номер телефона</h4>
          <a href={`tel:${links.tel}`} className="phone_link">
            {links.tel}
          </a>
        </div>
        <div className="side-info">
          <h4>Email Address</h4>
          <a href={`mailto:${links.email}`} className="phone_link">
            {links.email}
          </a>
        </div>
        <div className="side-info">
          <h4>Наш адрес</h4>
          {Object.keys(address).map((key, index) => (
            <div
              key={index}
              style={{
                listStyle: "none",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "15px",
              }}
            >
              <p>
                <FaMapMarkerAlt size={20} className="color-red" />
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                {address[key]}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
