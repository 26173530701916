import React, {useState} from 'react';
import {Outlet} from "react-router-dom"
import NavbarComponent from "./NavbarComponent";
import RightSideBar from "./RightSideBar";
import Footer from "./Footer";

const Layout = () => {
    const [showSideBar, setShowSideBar] = useState(false);
    return (
        <div>
            <NavbarComponent openSideBar={() => setShowSideBar(true)}/>
            <RightSideBar show={showSideBar} closeSideBar={() => setShowSideBar(false)}/>
            <Outlet/>
            <Footer/>
        </div>
    );
};

export default Layout;
