import React from "react";
import {
  FaBoxOpen,
  FaEnvelopeOpen,
  FaFacebook,
  FaHeadset,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhone,
  FaSearchLocation,
  FaTelegram,
} from "react-icons/fa";
import { address, links } from "../const/links";

const Footer = () => {
  return (
    <footer className="Footer container-fluid">
      <div className="container">
        <div className="row g-3">
          <div className="col-md-3">
            <img src={"images/logo.png"} alt="" />
          </div>
          <div className="col-md-3 faq-box">
            <FaBoxOpen size={36} className="color-red" />
            <h4>Вы хотите прекрасный продукт в Китае</h4>
            <a href={links.admin}>Eще</a>
          </div>
          <div className="col-md-3 faq-box">
            <FaSearchLocation size={36} className="color-red" />
            <h4>Как заказать товар в Китае?</h4>
            <a href={links.admin}>Связаться с нами</a>
          </div>
          <div className="col-md-3 faq-box">
            <FaHeadset size={36} className="color-red" />
            <h4>Вам нужна помощь?</h4>
            <a href={links.admin}>Связаться с нами</a>
          </div>
        </div>
        <div className="row footer-middle g-4">
          <div className="col-md-4">
            <h3 className="footer-title">UzPartnerCargo</h3>
            <span className="before-border" />
            <ul>
              <li>
                <a href="/#" className="footer-link">
                  Главная
                </a>
              </li>
              <li>
                <a href="/#/about-us" className="footer-link">
                  О Нас
                </a>
              </li>
              <li>
                <a href="/#/our-services" className="footer-link">
                  Наши Услуги
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h3 className="footer-title">Информация</h3>
            <span className="before-border" />
            <div className="d-flex info-block">
              <FaPhone size={20} className="color-red" />
              <div className="w-100">
                <a href={`tel:${links.tel}`} className="phone_link">
                  {links.tel}
                </a>
              </div>
            </div>
            <div className="d-flex info-block">
              <FaEnvelopeOpen size={20} className="color-red" />
              <div className="w-100">
                <a href={`mailto:${links.email}`} className="phone_link">
                  {links.email}
                </a>
              </div>
            </div>
            <div className="side-info">
              {Object.keys(address).map((key, index) => (
                <div
                  key={index}
                  style={{
                    listStyle: "none",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "15px",
                    color: "#fff",
                  }}
                >
                  <p>
                    <FaMapMarkerAlt size={20} className="color-red" />
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {address[key]}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="footer-title">О нас</h3>
            <span className="before-border" />
            <p className="info">
              Мы осуществляем доставку стандартных грузов, сборных, опасных и
              требующих специальных условий транспортировки, негабаритных и
              тяжеловесных отправок.
            </p>
            <div className="d-flex socials">
              <a href={links.facebook} className="social-link">
                <FaFacebook size={18} />
              </a>
              <a href={links.telegram} className="social-link">
                <FaTelegram size={18} />
              </a>
              <a href={links.instagram} className="social-link">
                <FaInstagram size={18} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          Copyright @ 2020 <a href="/"> UzPartnerCargo</a>. All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
