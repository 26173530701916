import { FaAnchor, FaAtom, FaRegChartBar } from "react-icons/fa";

const links = {
  instagram: "https://instagram.com/uzpartnercargo",
  telegram: "https://t.me/uzpartnercargo",
  facebook: "https://www.facebook.com/profile.php?id=100070847917554",
  tel: "+998 (95) 075 07 07",
  email: "info@uzpartner.com",
  admin: "https://t.me/uzpartnercargoadmin",
};

const address = {
  1: "Узбекистан Адрес в г.Коканд, ул.Навбахор, 26В дом. Ориентир: ресторан «Шердор»",

  2: "Адрес в г.Ташкент, ул.Шота Руставели 12 Ориентир: GrandMir Hotel",

  // 3: "Китай Адрес склада в г.Гуанчжоу 广州市白云石井石庆路74号大管家仓储园",

  4: "Адрес склада в Yiwu deep international B1-1-2 warehouse",
};

const details = [
  {
    title: "Сборные грузы",
    description: `Доставка сборных грузов из Китая – одна из услуг, которую оказывает логистическая компания «UzPartnercargo».\n
    Мы всегда ответственно подходим к решению логистических задач и выполняем транспортировку с минимальным участием клиента в организационных процессах`,
  },
  {
    title: `Авиаперевозки`,
    description: `Авиадоставка - это самый оперативный и универсальный вид грузовых перевозок, так как можно перевезти груз практически любого габарита и массы!\n
    Обращайтесь в «Uzpartnercargo» и мы доставим Ваш груз в Узбекистан из любой точки Мира, по самым лучшим ценам.\n
    Принимаем заявки на небольшой объём груза - от 5 кг\n
    Крупный груз - от 200 кг`,
  },
  {
    title: `Морские перевозки`,
    description: `Морские перевозки грузов – достаточно популярная услуга, особенно для тех компаний, чья деятельность связана с транспортировкой крупногабаритных грузов.\n
    Пункт, который никак нельзя обойти стороной\n
    Оптимальная стоимость подобных услуг.\n
    Именно поэтому морские перевозки различных грузов из Китая в Узбекистан пользуются большим спросом`,
  },
  {
    title: `Контейнерные перевозки`,
    description: `Контейнерные перевозки всегда в тренде.\n
    Есть несколько причин:\n
    Оборудование хорошо сохраняет любой вид груза!\n
    Исключены потери!\n
    Сокращаются расходы на тару!\n
    Вывод: Выгодно, удобно и безопасно!`,
  },
];

const service = [
  {
    title: "Грузоперевозки из Китая",
    img_src: "/images/home/auto-cargo.jpg",
  },
  {
    title: "Авиаперевозки из Китая",
    img_src: "/images/home/avia-cargo.jpg",
  },
  {
    title: "Автоперевозки из Китая",
    img_src: "/images/home/rail-cargo.jpg",
  },
  {
    title: "Морские перевозки из Китая",
    img_src: "/images/home/search-cargo.png",
  },
];

const team = [
  {
    name: "Madrahimov Islomxon",
    img_src: "/images/people/Madrahimov Islomxon.png",
    role: "Rahbar",
  },
  {
    name: "Madixonov Nurulloxon",
    img_src: "/images/people/Madixonov Nurulloxon.png",
    role: "Savdo Bo'limi Boshlig'i",
  },
  {
    name: "Maxsudov Abdurashid",
    // img_src: "/images/people/Maxsudov Abdurashidxon.jpg",
    role: "Bojxona Menejeri",
  },
  {
    name: "Mirazizov Miralisher",
    img_src: "/images/people/Mirazizov Miralisher.png",
    role: "Finansist",
  },
  {
    name: "Ro'zmatov Yusufxon",
    img_src: "/images/people/Ro'zmatov Yusufxon.png",
    role: "Chet El Menejeri",
  },
  {
    name: "Usmonov Muhiddin",
    img_src: "/images/people/Usmonov Muhiddin.jpg",
    role: "Deklarant",
  },
];

const cards = [
  {
    img: "images/about-us/card-1.png",
    title: "Опыт",
    text: `Реальность нашей команды – это огромное количество успешно
    выполненных заказов, широчайшая база постоянных клиентов,
    сотрудничество с ведущими компаниями и безупречное
    качество услуг процессы которых отлажены с годами.`,
    icon: <FaAnchor className="color-red" height={24} />,
  },
  {
    img: "images/about-us/card-2.jpg",
    title: "Профессионализм",
    text: `За годы активной деятельности, штат компании пополнялся
    высококвалифицированными специалистами и экспертами,
    формируя в своем составе отделы, которые специализируются
    на экспорте, импорте, документообороту и поиску продукции.`,
    icon: <FaAtom className="color-red" height={24} />,
  },
  {
    img: "images/about-us/card-3.jpg",
    title: "Возможности",
    text: `На сегодняшний день UzPartnerCargo обладает всеми
    возможностями для безопасной, быстрой и отлаженной
    перевозке грузов по земле и воздуху, располагает базой
    агентов и субподрядчиков на всем пути следования заказа,
    что позволяет качественно оказывать логистические услуги.`,
    icon: <FaRegChartBar className="color-red" height={24} />,
  },
];

const BOT_LINK = `https://api.telegram.org/bot5994277169:AAHMiJI4I-aAM--vgWPsbu-DgUku7H1wCV8`;

export { links, address, details, service, team, cards, BOT_LINK };
