import React from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import {
  FaPhone,
  FaPlaneArrival,
  FaRegPaperPlane,
  FaSearch,
  FaTruck,
  FaUser,
} from "react-icons/fa";
import { GiCargoCrate } from "react-icons/gi";
import BrandArea from "../../components/BrandArea";
import { details, links, service } from "../../const/links";
import { sendMessage } from "../../request";
import { Toast, ToastContainer } from "react-bootstrap";
import { useState } from "react";

const OurServices = () => {
  const sliderSettings = {
    dots: false,
    controls: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const wawd = "images/our-service/contact.jpg";
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  return (
    <div className="Our-Services p-0">
      <Helmet>
        <title>UzPartnerCargo - Logistics company</title>
        <meta name="description" content="Generated by create next app" />
        {/*<link rel="icon" href="/favicon.ico" />*/}
      </Helmet>

      <header className="container-fluid d-flex align-items-center justify-content-center">
        <h1 className="display-1 color-white fw-bold">Наши Услуги</h1>
      </header>

      <section className="what-are-we-doing container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-content">
                <span className="size-lg border-text color-black">01</span>
                <h1 className="section-title mb-0">
                  <span className="before-border" />
                  Чем мы занимаемся?
                </h1>
                <h1 className="fw-bold mt-3 mb-0">
                  UzPartnerCargo - Ваш надёжный партнёр по грузоперевозкам
                </h1>
              </div>
              <p className="small-text">
                С первых дней создания UzPartnerCargo стала активно развиваться
                на рынке транспортах услуг Узбекистана, заключая долгосрочные
                контракты по осуществлению грузовых перевозок, поиска
                необходимых товаров на территории Китая, полному таможенному
                оформлению по экспортно-импортным операциям, для предприятий
                нашей страны.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                className="w-100 h-100"
                src={wawd}
                alt=""
                style={{
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="our-service container-fluid">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="col-lg-7">
              <div className="title-content d-flex flex-column align-items-center">
                <span className="size-lg border-text color-white">02</span>
                <h1 className="section-title color-white">
                  <span className="before-border color-white" />
                  О нас
                  <span className="after-border color-white" />
                </h1>
                <h1 className="fw-bold mt-3 mb-5 color-white text-center">
                  Современная и надежная логистическая компания{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Slider {...sliderSettings}>
            {details.map((item, index) => (
              <div className="slider-item" key={index}>
                <div className="card">
                  <div className="card-header">
                    <img
                      src={service[index].img_src}
                      className="image"
                      alt={"Slider-1"}
                    />
                    <div className="overlay">
                      <div className="text-content text-start">
                        <h4>{item.title}</h4>
                        <div className="d-flex flex-column align-items-start">
                          <p className="small-text">{item.description}</p>
                        </div>
                        <a href="/" className="btn btn-link">
                          Детальнее
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-footer text-center"
                    style={{
                      height: "12vh",
                    }}
                  >
                    <a href="/">{item.title}</a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section className="statistics container-fluid">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="title-content col-md-8 text-center d-flex flex-column align-items-center">
              <span className="size-lg border-text color-white">03</span>
              <h1 className="section-title text-center">
                <span className="before-border" />
                Статистика компании
                <span className="after-border" />
              </h1>
              <h1 className="fw-bold mt-3 mb-5 color-white">
                За годы работы у нас немало успешных проектов
              </h1>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <div className="info-card">
                <FaTruck className="color-red" size={80} />
                <h1>
                  16 312 <span>+</span>
                </h1>
                <span>Грузоперевозок</span>
                <div className="progress-bar" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-card">
                <FaSearch className="color-red" size={80} />
                <h1>
                  9 753 <span>+</span>
                </h1>
                <span>Найдено товаров</span>
                <div className="progress-bar" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-card">
                <FaPlaneArrival className="color-red" size={80} />
                <h1>
                  4 632 <span>+</span>
                </h1>
                <span>Авиаперевозок</span>
                <div className="progress-bar" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-card">
                <GiCargoCrate className="color-red" size={80} />
                <h1>
                  615 <span>+</span>
                </h1>
                <span>Спецпроектов</span>
                <div className="progress-bar" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="support container-fluid">
        <div className="container">
          <div className="d-flex justify-content-center information">
            <div className="col-md-8 text-center">
              <span>24/7 Активной поддержки</span>
              <h1>Нужна помощь с заказом?</h1>
              <p>
                Команда наших менеджеров и консультантов работает для Вас 24/7 и
                готова оказать быструю и профессиональную поддержку. Ваша заявка
                будет рассмотрена в течении 1 дня!
              </p>
              <a href={links.admin} className="btn">
                Связаться
                <FaRegPaperPlane className="ms-2" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="working-progress container-fluid">
        <div className="container">
          <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8">
            <div className="title-content d-flex flex-column align-items-center">
              <span className="size-lg border-text color-black">04</span>
              <h1 className="section-title color-red">
                <span className="before-border color-red" />
                Рабочий процесс
                <span className="after-border color-red" />
              </h1>
              <h1 className="fw-bold mt-3 mb-5 color-black text-center">
                Наша система и бизнес процессы отточены временем!{" "}
                <span className="after-border color-white" />
              </h1>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-4">
              <div className="progress-card">
                <div className="card-image">
                  <img src={"/images/our-service/progress-1.png"} alt="" />
                </div>
                <div className="card-content">
                  <h2>Размещение заказа</h2>
                  <p>
                    Разместите ваш заказ связавшись с нами. Не переживайте, наши
                    специалисты сориентируют Вас и помогут с первого звонка!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="progress-card">
                <div className="card-image">
                  <img src={"/images/our-service/progress-2.png"} alt="" />
                </div>
                <div className="card-content">
                  <h2>Поиск товара</h2>
                  <p>
                    Оставив свой заказ, вы можете больше не переживать ни о чём
                    - наши специалисты всё сделают на наивысшем уровне.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="progress-card">
                <div className="card-image">
                  <img src={"/images/our-service/progress-3.png"} alt="" />
                </div>
                <div className="card-content">
                  <h2>Получение товара</h2>
                  <p>
                    На протяжении всего пути вашего груза и до самого момента
                    получения, наши менеджеры будут на связи с вами, оказывая
                    поддержку.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="request-quote container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-content">
                <span className="size-lg border-text color-white">05</span>
                <h1 className="section-title color-white">
                  <span className="before-border color-white" />
                  Запросить квоту
                </h1>
                <h2 className="fw-bold mt-3 mb-2 color-white">
                  Мы Предоставляем транспортные сервисы «под ключ»
                </h2>
              </div>
            </div>
            <div className="col-lg-6 bg-white position-relative ps-5">
              <form
                onSubmit={async (e) => {
                  try {
                    e.preventDefault();
                    const formDate = new FormData(e.target);
                    const data = Object.fromEntries(formDate);
                    await sendMessage(data);
                    e.target.reset();
                    setToastShow(true);
                    setToastMessage("Сообщение отправлено");
                  } catch (error) {
                    setToastShow(true);
                    setToastMessage("Ошибка отправки сообщения");
                  }
                }}
              >
                <div className="forms-group">
                  <div className="row g-4">
                    <div className="col-md-12">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ваше имя"
                          name="Ism"
                        />
                        <FaUser size={20} className="color-red" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ваш номер"
                          name="Tel"
                        />
                        <FaPhone size={20} className="color-red" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <select
                        className="form-control form-select"
                        name="Ketish joyi"
                      >
                        <option selected value="-">
                          Отправление
                        </option>
                        <option value="Шанхай">Шанхай</option>
                        <option value="Гуанчжоу">Гуанчжоу</option>
                        <option value="Пекин">Пекин</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <select
                        className="form-control form-select"
                        name="Kelish joyi"
                      >
                        <option selected value="-">
                          Прибытие
                        </option>
                        <option value="Ташкент">Ташкент</option>
                        <option value="Самарканд">Самарканд</option>
                        <option value="Наманган">Наманган</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <select className="form-control form-select" name="Turi">
                        <option selected value="Грузоперевозки">
                          Грузоперевозки
                        </option>
                        <option value="Авиаперевозки">Авиаперевозки</option>
                        <option value="Железнодорожные перевозки">
                          Железнодорожные перевозки
                        </option>
                        <option value="Поиск товаров">Поиск товаров</option>
                        <option value="Таможенное оформление">
                          Таможенное оформление
                        </option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Kubometr ㎥"
                        name="Kub"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Kilogram"
                        name="KG"
                      />
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn w-100 bg-red color-white rounded-pill"
                        type="submit"
                      >
                        Отправить
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="brands container-fluid">
        <BrandArea />
      </section>
      <ToastContainer position="top-center" containerPosition="fixed">
        <Toast
          show={toastShow}
          onClose={() => setToastShow(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">UZ PARTNER CARGO</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default OurServices;
