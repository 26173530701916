import React from 'react';
import Slider from 'react-slick'

const BrandArea = () => {

    const sliderSettings = {
        dots: false,
        controls: false,
        infinite: true,
        speed: 2000,
        // autoplaySpeed: 2000,
        // cssEase: "linear",
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    }

    return (
        <div className="BrandArea container-fluid">
            <div className="container-md">
                <Slider {...sliderSettings}>
                    <div className="brand-item">
                        <img src={"/images/our-service/delivery.png"} alt=""/>
                    </div>
                    <div className="brand-item">
                        <img src={"/images/our-service/fast-truck.png"} alt=""/>
                    </div>
                    <div className="brand-item">
                        <img src={"/images/our-service/fasttk.png"} alt=""/>
                    </div>
                    <div className="brand-item">
                        <img src={"/images/our-service/product.png"} alt=""/>
                    </div>
                    <div className="brand-item">
                        <img src={"/images/our-service/logistics.png"} alt=""/>
                    </div>
                    <div className="brand-item">
                        <img src={"/images/our-service/express.png"} alt=""/>
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default BrandArea;
