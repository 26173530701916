import React from "react";
import { Helmet } from "react-helmet";
import { FaRegCheckCircle } from "react-icons/fa";
import BrandArea from "../../components/BrandArea";
import { cards, team } from "../../const/links";

const AboutUsPage = () => {
  const aboutUs = "images/about-us/about-us.png";

  return (
    <div className="About-Us p-0">
      <Helmet>
        <title>UzPartnerCargo - Logistics company</title>
        <meta name="description" content="Generated by create next app" />
      </Helmet>

      <main>
        <header className="container-fluid d-flex align-items-center justify-content-center">
          <h1 className="display-1 color-white fw-bold">О Нас</h1>
        </header>

        <section className="about-us container-fluid">
          <div className="container">
            <div className="row g-1">
              <div className="col-lg-6 col-md-5">
                <img src={aboutUs} className="w-75" alt="" />
              </div>
              <div className="col-lg-6 col-md-7">
                <div className="title-content">
                  <span className="size-lg border-text color-black">01</span>
                  <h1 className="section-title">
                    <span className="before-border" />О нас
                  </h1>
                  <h1 className="fw-bold mt-3 mb-5">
                    Современная и надежная логистическая компания{" "}
                  </h1>
                </div>
                <p>
                  С первых дней создания UZ PARTNER CARGO стала активно
                  развиваться на рынке транспортах услуг Узбекистана, заключая
                  долгосрочные контракты по осуществлению грузовых перевозок,
                  поиска необходимых товаров на территории Китая, полному
                  таможенному оформлению по экспортно-импортным операциям, для
                  предприятий нашей страны.
                </p>
                <div className="row">
                  <div className="col-sm-6 about-content">
                    <div className="d-flex">
                      <FaRegCheckCircle className="color-red" size={45} />
                      <div className="ms-3">
                        <h2>100%</h2>
                        <p>Качества</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 about-content">
                    <div className="d-flex">
                      <FaRegCheckCircle className="color-red" size={45} />
                      <div className="ms-3">
                        <h2>100%</h2>
                        <p>ДОВЕРИЯ</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 account-info d-flex">
                  <div className="box-70 me-3">
                    <img
                      src={team[0].img_src}
                      alt=""
                      style={{
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                    />
                  </div>
                  <div className="account-title">
                    <h4>{team[0].name}</h4>
                    <p>{team[0].role}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-we container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="title-content">
                  <span className="size-lg border-text color-black">02</span>
                  <h1 className="section-title">
                    <span className="before-border" />
                    Почему выбирают нас
                  </h1>
                  <h1 className="fw-bold mt-3 mb-5">
                    Наши качества и преимущества
                  </h1>
                </div>
              </div>
              <div className="col-md-6">
                <p>
                  Мы предлагаем высококачественные логистические решения для
                  компаний, работающих в Узбекистане и по всему миру. Имея более
                  200 сотрудников, а также филиалы и офисы в городах Китая, мы
                  поддерживаем планы роста и расширения наших клиентов на этом
                  захватывающем и динамичном рынке.
                </p>
              </div>
            </div>
            <div className="row">
              {cards.map((card, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="card h-100">
                    <div className="card-header">
                      <img src={card.img} alt="" />
                    </div>
                    <div className="card-body">
                      <h3>
                        {card.icon}
                        {card.title}
                      </h3>
                    </div>
                    <div className="card-footer">
                      <p>{card.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* <section className="video-section container-fluid"></section> */}

        <section className="our-team container-fluid">
          <div className="container">
            <div className="d-flex justify-content-center">
              <div className="title-content col-md-6 text-center d-flex flex-column align-items-center">
                <span className="size-lg border-text color-black">03</span>
                <h1 className="section-title te">
                  <span className="before-border" /> Наша команда
                </h1>
                <h1 className="fw-bold mt-3 mb-5">
                  Команда наших специалистов
                </h1>
              </div>
            </div>
            <div className="row">
              {team.map((member, index) => (
                <div className="col-lg-4 col-md-6 g-5" key={index}>
                  <div className="team-member">
                    <div className="team-img">
                      <img src={member.img_src} alt="" />
                    </div>
                    <div className="team-text">
                      <h3>{member.name}</h3>
                      <span>{member.role}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="brands container-fluid">
          <BrandArea />
        </section>
      </main>
    </div>
  );
};

export default AboutUsPage;
